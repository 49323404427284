import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import Highlight from 'src/components/Layout/NewHighlight'
import BreadCrumb from 'src/components/Breadcrumb'
import { PrivacyPolicy } from 'src/components/PrivacyPolicy'

import cookiesPolicyHtml from '../../../components/PrivacyPolicy/politica-de-cookies.html'
import cookiesPolicyMenu from '../../../components/PrivacyPolicy/politica-de-cookies.json'

import { CustomStyle } from './../style'

import pageContext from './pageContext.json'
import usePageQuery from './../pageQuery'

function PoliticaDeCookies () {
  const data = usePageQuery()
  const title = 'Política de cookies'
  const htmlContent = cookiesPolicyHtml
  const menuContent = cookiesPolicyMenu

  return (
    <Layout pageContext={pageContext}>
      <Highlight customStyle={CustomStyle} highlightContext={pageContext} bannerDesktop={data.bannerPoltiticaPrivacidadeDesktop.fluid} bannerMobile={data.bannerPoltiticaPrivacidadeMobile.fluid}>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12'>
            <BreadCrumb
              sectionName={title}
              type='informações'
              text='Central de privacidade'
              link='/politica-de-privacidade'
            />
          </div>
          <div className='col-12 mb-2 mb-lg-5'>
            <h1 className='fs-30 fs-md-50 text-white fw-600'>{title}</h1>
          </div>
        </div>
      </Highlight>
      <section className='py-md-4'>
        <div className='container'>
          <PrivacyPolicy
            title={title}
            htmlContent={htmlContent}
            menuContent={menuContent}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PoliticaDeCookies
